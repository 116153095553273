.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background: linear-gradient(180deg, #050D1F 0%, #0D1A38 100%);
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.LoginNavBar{
  padding-left: 20px;
  padding-top: 20px;
}

.LoginBGImage{
  position: absolute;
  max-width: 100%;
  /* max-height: 100%; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}


.loginContent{
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #EDEFF3;
  z-index: 1;
}

.loginMessage{
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 20px;
}

.StarBG{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  inset: 0;
  background: linear-gradient(180deg, #050D1F 0%, #0D1A38 100%);
}

.LoginWithGoogleButton  {
  color: #D4D9E5 !important;
  background-color: #1D2641 !important;
  border: 1px solid #525762 !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  min-width: 220px  !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

.LoginButton  {
  color: #514713 !important;
  background-color: #FFE86E !important;
  border: 1px solid #514713 !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 4px 20px !important;
  min-width: 100px  !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

.LoginButtonContainer{
  margin-top: 10px;
  margin-bottom: 10px;
}

.GoogleIcon {
  font-size: 20px;
  margin-right: 6px;
}

.LoginAlternative{
  margin-top: 20px;
}

.LoginBox{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #182037;
  border-radius: 10px;
  padding: 20px 30px;
  row-gap: 10px;
}

.LoginBox .MuiFormControl-root {
  min-width: 300px;
}

.YellowLink{
  color: #FFE86E;
  text-decoration: underline  !important;
  cursor: pointer;
}

.ForgotPassword{
  font-weight: 300;
  font-size: 14px;
  color: #F3F3F3;
  text-decoration: underline !important;
}