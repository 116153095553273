.SavedPageTable{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    column-gap: 40px;
    row-gap: 20px;
    padding: 0% 5% ;
    overflow-y: auto;
    padding-bottom: 10px;
}

.TableRowLink{
    color: #3F3F3F;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.SavedPageDataGrid {
    background: #F8F8FB;
    border: 1px solid #CECECE;
    /* box-shadow: 0px 3px 3px rgb(0 0 0 / 25%); */
    border-radius: 10px;
}

.SavedPageDataGrid .MuiDataGrid-row:nth-child(odd){
    background: #f2f2f4;
}