.DataSearchBar .MuiFormControl-root {
    width: 90%;
    max-width: 500px;
}

.SearchPage {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    row-gap: 10px;;
}

.SearchPage .LoginButton{
    max-width: 200px;

}

.SearchPage .SavedPageTable {
    flex: 1 1 auto;
    min-height: 400px;
}