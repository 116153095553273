.CreatePage{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
    max-height: 100%;

}

.CreatePageTitle{
    font-size: 22px;
    font-weight: 500;
    color: #3F3F3F;
    margin-bottom: 10px;
    margin-left: 5%;
}

.CreatePageGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    column-gap: 40px;
    row-gap: 20px;
    padding: 0% 5% ;
    overflow-y: auto;
    padding-bottom: 10px;
}

.CreateBox{
    width: 280px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #F8F8FB;
    border-radius: 10px;
    color: #3F3F3F;
    padding: 20px;
    border: 1px solid #CECECE;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.WIPBox {
    width: 300px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /* background-color: #C2C36C; */
    border-radius: 10px;
    color: #3F3F3F;
    padding: 20px;
    /* border: 1px solid #CECECE; */
}

.WIPBox .CreateBoxSubtitle{
    text-align: center;
}

.CreateBox:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.4);
}

.CreateBoxText{
    font-weight: 600;
    font-size: 20px;
}


.CreateBoxSubtitle{
    text-align: left;
    text-decoration: none !important;
    color: #838383;
    font-size: 16px;
    font-weight:    400;
    margin-top: 10px;
}

.CreatePageContent{
    display: flex;
    flex-direction: row;
    width: 90%;
    column-gap: 8%;
    row-gap: 20px;
    flex: 1 1 auto;
    max-height: calc(100% - 34px);
    justify-content: space-around;
    padding: 0% 5%;
}

.CreatePageInput{
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    /* background: #FDFDFF; */
    /* padding: 12px 20px; */
    /* border-radius: 8px; */
    /* border: 1px solid #CECECE; */
    overflow-y: auto;
    height: calc(100% - 24px);
    padding-right: 5px;
}

.CreatePageInputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    flex: 1 1 auto;
    
}

.CreatePageInputLabel {
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 16px;
    color: #555;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.CreatePageInputGrid{
    display: grid;
    column-gap: 10px;
    grid-template-columns: 3fr 1fr;
    width: 100%;
    row-gap: 10px;
    border-right: 1px solid #e0e0e0;
    padding-right: 10px;
}

.CreatePageOutput{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* flex: 1 1 auto; */
    max-height: 100%;
}

.CreatePageOutputauto {
    flex: 1 1 auto;
}

.CreatePageOutput .MuiTextField-root{
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

}

.CreatePageOutput .MuiInputBase-inputMultiline{
    height: 100%;
    flex: 1 1 auto;
    padding-top: 0px !important;
    padding: 4px;
    padding-left: 12px;

}

.CreatePageOutputauto .MuiInputBase-multiline{
    height: 100%;
    flex: 1 1 auto;

    padding: 0px;
}

.CreatePageOutput textarea{
    height: calc(100% - 20px) !important;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0.1px;
}

.CreatePageButtonContainer{
    /* margin-top: 20px; */
    display: flex;
    flex-direction: row-reverse;
}

.MuiInputBase-root.MuiOutlinedInput-root{
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
}

.MuiInputBase-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.CreatePageOutputRow{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 15px;
    font-size: 14px;
}

.CreatePageOutputRow  .MuiInputBase-root {
    height: auto;
    flex: 0 0 auto;
}

.CreatePageOutputRow2{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.CreatePageInputLine{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.CreatePageInputNotes{
    color: #3F3F3F;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;

}

.CreatePageCheckbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    row-gap: 10px;
}

.CreatePageCheckboxTitle{
    color: #3F3F3F;
}

.CreateBoxLogo {
    margin-top: 10px;
    font-size: 30px;
    min-height: 42px;
}

.LinkedInLogo {
    color: #0A66C2;
  }

  .SubmitButton {
    color: #F0F0F0 !important;
    background-color: #113263 !important;
    /* border: 1px solid #876B0B !important; */
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
  }

  .SubmitButtonSmall {
    color: #0D63B4 !important;
    background-color: #EEF0F8 !important;
    /* border: 1px solid #876B0B !important; */
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 2px 2px !important;
    min-width: 20px  !important;
    font-size: 20px !important;
    box-shadow: none !important;
  }

  .NewButtonSmall {
    color: #0D63B4  !important;
    background-color: #EEF0F8 !important;
    /* background-color: #D7EAF8 !important; */
    /* border: 1px solid #0D63B4  !important; */
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 2px 2px  !important;
    min-width: 20px  !important;
    font-size: 20px !important;
    box-shadow: none !important;
  }

  .EditButtonSmall {
    color: #C2640D  !important;
    background-color: #EECAAA !important;
    border: 1px solid #C2640D  !important;
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 4px 4px !important;
    min-width: 20px  !important;
    font-size: 16px !important;
  }


  .CopyButton {
    color: #216042 !important;
    background-color: #78CEA5 !important;
    border: 1px solid #216042 !important;
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 6px 6px !important;
    min-width: 20px  !important;
    font-size: 12px !important;
  }


  .DeleteButton {
    color: #fe6767 !important;
    background-color: #ffbebe !important;
    border: 1px solid #fe6767 !important;
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 6px 6px !important;
    min-width: 20px  !important;
    font-size: 12px !important;
  }

  .LinkedInButton {
    color: #06315d !important;
    background-color: #0A66C2 !important;
    border: 1px solid #06315d !important;
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 2px 2px !important;
    min-width: 20px  !important;
    font-size: 20px !important;
  }

  .SearchLinkedInButton {
    color: #e6e6e6 !important;
    background-color: #0A66C2 !important;
    border: 1px solid #06315d !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 4px 10px !important;
    min-width: 20px  !important;
    font-size: 14px !important;
  }

  /* .LinkedInButtonLogo {
    background-color: #fff;
    border-radius: 2px;
  } */

  .LinkedInButtonLogoContainer {
    background-color: #fff;
    border-radius: 4px;
    height: 20px;
    display: flex;
    /* padding-top: -3px; */
  }
  .LinkedInButtonLogoContainer .LinkedInButtonLogo {
    padding-top: -3px;
  }

  .Mui-disabled{
    opacity: 0.7;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #CECECE !important;
  }

  .MuiMenuItem-root.MenuItemDefault{
    /* font-size: 12px; */
    font-weight: 300;
    font-style: italic;
  }

.CreatePageOutputCheckbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.CreatePageOutputCheckbox .CreatePageInputLabel {
    margin-bottom: 0px;
}

.CreatePageOutputCheckbox .MuiCheckbox-root{
    padding: 0px;
    margin-left: 5px;
}

  .CopyButtonContainer {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .TaylrTooltip .MuiTooltip-tooltip {
    background-color: #fff;
    border-radius: 4px;
    color: #323232;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 6px 12px;
    font-size: 14px;
    max-width: 400px;
    margin: 2px;
    /* word-wrap: break-word; */
    font-weight: 400;
    border: 1px solid #CECECE;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  }

  .TaylrTooltip .MuiTooltip-arrow{
    color: #CECECE;

  }

  .Optional {
    font-weight: 300;
    font-size: 12px;
    margin-left: 5px;
  }

  .TaylrTooltip ul {
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
    color: #3F3F3F;
  }

  .RefineOutputExamples{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    cursor: pointer;
    padding-bottom: 10px;
    align-items: flex-start;
  }

  .RefineOutputExample{
    /* max-width: 200px; */
    font-size: 12px;
    background-color: rgba(0,0,0, 0.1);
    color: #3F3F3F;
    padding: 4px 8px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #CECECE;
  }

  .RefineOutputContainer{
    min-height: 225px;
  }

  .fileNameInput .MuiInputBase-root.MuiOutlinedInput-root {
    font-size: 16px;
    width: auto;
    color: #3F3F3F !important; 
    opacity: 1; 
    -webkit-text-fill-color:    #3F3F3F !important;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fileNameInput .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: #EEF0F8;
  }

  .fileNameInput .MuiOutlinedInput-notchedOutline {
    border: 0px solid #EEF0F8 !important;
    color: #3F3F3F !important; 
    -webkit-text-fill-color:    #3F3F3F !important;

  }

  .fileNameInput .MuiOutlinedInput-input{
    padding: 4px;
    padding-top: 4px !important;
    padding-bottom: 4px  !important;
    color: #3F3F3F !important; 
    -webkit-text-fill-color:    #3F3F3F !important;
    width: 200px;
    overflow:hidden;
    white-space:nowrap
  }

  .fileNameInput .MuiOutlinedInput-input.Mui-disabled{
    text-overflow: ellipsis;
  }

  .FileNameContainer{
    display: flex;
    align-items: center;
    margin-left: -4px;
    column-gap: 4px;
  }

  .editIcon{
    cursor: pointer;
    font-size: 18px
  }

  .editFinishIcon{
    color: #216042 ;
    cursor: pointer;
    font-size: 18px
  }

  .MuiModal-root{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EEF0F8;
    min-width: 600px;
    padding: 30px;
    border-radius: 10px;
  }

  .ModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ModalTitle {
    font-size: 22px;
    font-weight: 500;
    color: #3F3F3F;
  }

  .TemplateSelectRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
  }

  .TemplateButtons {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  .TemplateButtons .SubmitButton {
    min-width: auto;
    width: 26px;
    height: 26px;
    font-size: 12px;
    padding: 3px !important;
  }

  .TemplateSelectRow .MuiInputBase-root {
    width: 60%;
  }

  .ModalInputContent{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
  }