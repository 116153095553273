button {
    cursor: pointer;
}

#root {
    max-height: 100vh;
}

.app{
    max-height: 100%;;
}

a:-webkit-any-link   {
    text-decoration: none;
}