.SettingsPage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    grid-column-gap: 40px;
    column-gap: 40px;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding: 0% 5%;
    overflow-y: auto;
    padding-bottom: 10px;
}

.PlansPage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    grid-column-gap: 40px;
    column-gap: 40px;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding: 0% 5%;
    overflow-y: auto;
    padding-bottom: 10px;
}

.SettingsPageRow{
    display: flex;
    flex-direction: row;

}

.SettingsPageLeft{
    min-width: 200px;
}

.SettingsPlanName{
    text-transform: capitalize;
    font-weight: 500;
}

.SettingsPageSectionTitle{
    font-size: 18px;
    color: #3F3F3F;
    font-weight: 500;
    margin-bottom: 5px;

}

.PlansContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
}

.Plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #F8F8FB;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);

}

.PlanTitle {
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    color: #182037;
}

.PlanDescription {
    font-weight: 400;
    font-size: 20px;
    max-width: 300px;
    text-align: center;
    color: #0D1A38;
}

.PlanPrice {
    color: #182037;
    font-weight: 500;
    font-size: 57px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.PoundIcon{
    font-size: 24px;
    margin-top: 20px;
    margin-right: 3px;
}

.PlanPriceInterval{
    vertical-align: baseline;
    font-size: 24px;
    margin-right: 3px;
    align-self: flex-end;
    margin-bottom: 10px;
}

.PlanCredits{
    margin-top: -10px;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: #4B5763;
}

.PlanNumbeUsers{
    font-weight: 400;
    font-size: 18px;
    max-width: 300px;
    text-align: center;
    color: #0D1A38;
    margin-top: 30px;
}

/* .PlanButton{
    margin-bottom: 10px;
} */

.PlanBilling {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    /* Dark Grey */

    color: #3F3F3F;
    margin-bottom: 20px;
}

.PlanButton {
    text-transform: none !important;
    text-decoration: none !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    font-size: 16px !important;
    border-radius: 24px !important;
}

.CheckoutButton {
    background: #17204E !important;
    color: #EEF0F8 !important;
    border: 1px solid #15395D !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
}