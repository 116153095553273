.dashboard {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #EEF0F8;
  padding: 30px;
}

.dashboard__btn {
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  color: #cfcfcf;
  background-color: #17204E;
  margin-left: 5px;
}


.dashboard-header{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.dashboard-logo{
  /* padding: 10px; */
  padding-left: 0px;
  color: #3F3F3F;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #FFFFFF;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TaylrLogo{
  height: 22px;
  margin-right: 4px;
  margin-top: 1px;
  margin-bottom: -1px;
}

.dashboard-logo-ai {
  color: #FFE86E;
}

.dashboard-user-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-right: 20px;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3F3F3F;
}

.dashboard-layout{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  max-height: 100%;;
}

.dashboard-main{
  flex: 1 1 auto;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: #EEF0F8;
}


.dashboard-content{
  flex: 1 1 auto;
  width: calc(100% - 40px);
  max-height: calc(100% - 129px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #EEF0F8;
  padding: 10px 20px;
}

.nav-buttons{
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: linear-gradient(180deg, #050D1F 0%, #0D1A38 100%);
  /* background: #182037; */
  row-gap: 20px;
  justify-content: space-between;
}


.nav-buttons-top{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.nav-buttons-bottom{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: stretch;
}

.nav-button{
  color: #D8DCF0;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.navIcon {
  font-size: 24px;
}

.DashboardIcon {
  color: #C85F5F;
}

.CreateIcon {
  color: #C2C36C;
}

.HelpIcon{
  margin-top: 2px;
}

.LogoutIcon{
  margin-top: 2px;
}

.nav-button:hover{
  color: #fff;
}

.LogoutIcon{
  transform: rotateY(180deg);
}

.dashboard-userimg{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.navCredits {
  border: 1px solid #344a7c;
  border-radius: 8px;
  padding: 10px;
  color: #D8DCF0;
  margin-bottom: 40px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.CreditsNumberRed{
  color: #FF7878;
}

.CreditsTitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #D8DCF0;
  column-gap: 10px;
}

.CreditsIcon {
  color: #3075d8;
  font-size: 24px;
}

.CreditsBar{
  width: 100%;
  height: 10px;
  background: #CECECE;
  border-radius: 4px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 4px;
}

.CreditsBar{
  width: 100%;
  height: 10px;
  background: #CECECE;
  border-radius: 4px;
  display: flex;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.8);
}

.CreditsBarFilled{
  background: #3075d8;
  border-radius: 4px;
}

.CreditsNumber{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CreditsMessage{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  color: #FF7878;
  text-decoration: underline;
  cursor: pointer;
}

.CreditsMessage.CreditsMessageInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  color: #FFE86E;
  text-decoration: none;
  cursor: default;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.TaylrModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TaylrModalContent{
  background-color: #EEF0F8;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.TaylrModalTitle{
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
}

.TaylrModalContentSentMessage{
  font-weight: 300;
  font-size: 14px;
  min-height: 20px;
  margin-top: -4px;
}

.Breadcrumbs{
  margin-left: calc(5% + 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2px;
  color: #3F3F3F;
  text-transform: capitalize;
}

.BreadcrumbDivider{
  font-size: 18px;
  margin-top: 4px;
}

.BreadcrumbLink{
  /* font-weight: 500; */
  /* text-decoration: underline !important; */
  color: #3F3F3F;
}

.SavedIcon{
  color: #43A878;
}

.CreditsLink{
  /* display: flex; */
  text-align: center;
  text-decoration: underline !important;
  color: #FFE86E;
  width: 100%;
}

.DashboardDisclaimer {
  color: #6D6D6D;
  font-size: 13px;
  text-align: center;
  padding: 5px;
  padding-bottom: 10px;
}